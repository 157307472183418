@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
  background-color: #F4F6F7;
  font-family: 'Montserrat', sans-serif;
  scroll-behavior: smooth;
}

.bg-hero-rect {
  background: linear-gradient(256.25deg, #7AD1EE 0%, #1B6497 142.37%);
  background-repeat: no-repeat;
  background-size: cover;
}

.bg-hero {
  background-image: url("./assets/imgs/blurred-hero.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.bg-gradient-light {
  background: linear-gradient(263.01deg, #CFF0FB 0.01%, #84D7F2 90.46%);
  background-repeat: no-repeat;
  background-size: cover;
}

.bg-team {
  background-image: url("./assets/imgs/bg-team.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.border-about {
  background-image: url("./assets/imgs/border.png");
  background-repeat: no-repeat;
  background-size: contain;
}

.react-multiple-carousel__arrow {
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
}

.carousel-item {
  display: flex;
}

.bg-backdrop {
  background-image: url("./assets/imgs/backdrop.png");
  background-repeat: no-repeat;
  background-size: cover;
}

#map > ymaps {
  border-radius: 20px;
  overflow: hidden;
}

.bg-questions {
  background-image: url("./assets/imgs/bg-questions.png");
  background-repeat: no-repeat;
  background-size: cover;
}

@media (max-width: 768px) {
  .bg-hero {
    background-image: url("./assets/imgs/bg-mob-hero.png");
  }

  .border-about {
    background-image: none;
  }

  .bg-team {
    background-image: url("./assets/imgs/bg-mob-team.png");
  }

  .bg-questions {
    background: linear-gradient(263.01deg, #CFF0FB 0.01%, #84D7F2 90.46%);
  }
}

@layer components {

  .container {
    padding-right: 1rem;
    padding-left: 1rem;
    margin-right: auto;
    margin-left: auto;
  }

  @media (min-width: 768px) {
      .container {
          width: 760px;
      }
  }
  @media (min-width: 992px) {
      .container {
          width: 970px;
      }
  }

  @media (min-width: 1200px) {
      .container {
          width: 1170px;
      }
  }

  @media (min-width: 1400px) {
    .container {
        width: 1370px;
    }
}
}